import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MasterDataService } from "./services/master-data.service";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  @ViewChild('demoVideo', { static: true }) demoVideo: ElementRef
  dtTrigger: Subject<any> = new Subject();
  constructor(
    private masterDataService: MasterDataService,
    private toastr: ToastrService
  ) {}
  title = "Hud-Gov";

  dtOptions: any = {};
  searchObject: any = {};

  properties: any = [];
  states: any = [];
  getStates() {
    this.masterDataService.getStates().subscribe((resp: any) => {
      this.states = resp.data;
    });
  }

  appartmentTypes: any = [];
  getAppartmentTypes() {
    this.masterDataService.getAppartmentTypes().subscribe((resp: any) => {
      this.appartmentTypes = resp.data;
    });
  }

  getDataByState() {
    this.searchObject.city = undefined;
    this.searchObject.county = undefined;
    this.searchObject.zipCode = undefined;
    this.searchObject.propertyName = "";
    this.getCitiesByState();
    this.getCountiesByState();
    this.getZipCodesByState();
    this.properties = [];
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
    this.isSearched = false;
  }

  cities: any = [];
  getCitiesByState() {
    this.masterDataService
      .getCitiesByState(this.searchObject.stateCode)
      .subscribe((resp: any) => {
        this.cities = resp.data;
      });
  }

  counties: any = [];
  getCountiesByState() {
    this.masterDataService
      .getCountiesByState(this.searchObject.stateCode)
      .subscribe((resp: any) => {
        this.counties = resp.data;
      });
  }

  zipCodes: any = [];
  getZipCodesByState() {
    this.masterDataService
      .getZipCodesByState(this.searchObject.stateCode)
      .subscribe((resp: any) => {
        this.zipCodes = resp.data;
      });
  }

  isSearched = false;

  ngOnInit(): void {
    this.getStates();
    this.getAppartmentTypes();
    this.dtOptions = {
      pageLength: 10,
      lengthMenu: [1, 5, 10, 25, 50, 100],
      select: true,
      dom: "Blfrtip",
      buttons: ["print", "excel", "pdf"],
    };
  }

  onVideoClose(){
    this.demoVideo.nativeElement.pause();
    // this.demoVideo.nativeElement.load();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  selectedBedrooms: any = [];
  selectOrDeselectBedroom(bedroomType) {
    if (this.selectedBedrooms.length === 0) {
      this.selectedBedrooms.push(bedroomType);
    } else {
      if (this.selectedBedrooms.indexOf(bedroomType) >= 0) {
        this.selectedBedrooms.splice(
          this.selectedBedrooms.indexOf(bedroomType),
          1
        );
      } else {
        this.selectedBedrooms.push(bedroomType);
      }
    }
    this.properties = [];
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
    this.isSearched = false;
    console.log(this.selectedBedrooms);
  }

  getPropertiesList() {
    if (
      !this.searchObject.city &&
      !this.searchObject.county &&
      !this.searchObject.zipCode &&
      !this.searchObject.propertyName
    ) {
      this.toastr.error(
        "Please select City or County or Zip code or enter Property name"
      );
      return;
    }
    const searchData = {
      ...this.searchObject,
      bedrooms: this.selectedBedrooms,
    };

    this.masterDataService
      .getPropertiesList(searchData)
      .subscribe((resp: any) => {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
        this.isSearched = true;
        this.properties = resp.data;
      });
  }
}

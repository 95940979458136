import { Injectable } from '@angular/core';
import { HttpMasterService } from './http-master.service';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(
    private httpMasterService: HttpMasterService
  ) { }

  getStates(){
    return this.httpMasterService.getService("masterdata/states")
  }

  getAppartmentTypes(){
    return this.httpMasterService.getService("masterdata/appartmentTypes")
  }

  getCitiesByState(stateCode){
    return this.httpMasterService.getService(`masterdata/cities/${stateCode}`)
  }

  getCountiesByState(stateCode){
    return this.httpMasterService.getService(`masterdata/counties/${stateCode}`)
  }

  getZipCodesByState(stateCode){
    return this.httpMasterService.getService(`masterdata/zipCodes/${stateCode}`)
  }

  getPropertiesList(data){
    return this.httpMasterService.postService(`masterdata/propertiesSearch`, data)
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpMasterService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getService(endPoint){
    return this.httpClient.get(`${environment.baseUrl}${endPoint}`)
  }

  postService(endPoint, data){
    return this.httpClient.post(`${environment.baseUrl}${endPoint}`, data)
  }
}
